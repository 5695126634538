import { inject, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { UserNotificationReadModel } from 'consistent-api-nvx-internal-sdk-dev';
import { firstValueFrom, map } from 'rxjs';

import { EnvironmentInterface } from '@/shared/environment.interface';
import { ENVIRONMENT } from '@/shared/environment.token';
import { LoggerService } from '@/shared/lib/services/logger.service';
import { TenantStore } from '@/shared/lib/stores/tenant.store';
import { UserStore } from '@/shared/lib/stores/user.store';
import { SIGNALR_HUB_NAME, SignalRMethod, SignalRNotificationType } from '@/shared/lib/types/signal-r.type';

type SubscriptionCallback = (data: UserNotificationReadModel) => Promise<void>;

@Injectable({ providedIn: 'root' })
export class SignalRService {
  authService = inject(MsalService);
  tenantStore = inject(TenantStore);
  userStore = inject(UserStore);
  logger = inject(LoggerService);
  environment: EnvironmentInterface = inject(ENVIRONMENT);

  private hubConnection!: HubConnection;
  private subscriptions: Record<string, SubscriptionCallback[]> = {};

  get connection() {
    return this.hubConnection;
  }

  constructor() {
    this.createConnection();
  }

  private createConnection() {
    if (this.hubConnection) return;

    const accessTokenRequest = {
      scopes: this.environment.msalConfig.apiScopes,
      account: this.authService.instance.getActiveAccount() || undefined,
    };

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.environment.eventSourceUrl}/${SIGNALR_HUB_NAME}`, {
        accessTokenFactory: () => {
          return firstValueFrom(
            this.authService.acquireTokenSilent(accessTokenRequest).pipe(map((res) => res.accessToken))
          );
        },
        withCredentials: false,
        transport: HttpTransportType.WebSockets,
      })
      // automatically attempts to reconnect if the connection is lost.
      // By default, the client will wait 0, 2, 10 and 30 seconds respectively before trying up to 4 reconnect attempts.
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Error)
      .build();
  }

  public startConnection = async () => {
    if (this.hubConnection.state === HubConnectionState.Connected) {
      return;
    }

    await this.hubConnection.start().then(
      () => {
        this.logger.log('Connected to SignalR');
        this.registerGlobalEvents();
      },
      (error) => {
        this.logger.error('Error Connecting to SignalR - ', error);
        if (this.hubConnection.state === HubConnectionState.Disconnected) {
          setTimeout(this.startConnection, 5000);
        }
      }
    );

    this.hubConnection.onreconnected(() => {
      this.registerGlobalEvents();
    });

    this.hubConnection.onclose((error) => {
      this.logger.error('Lost SignalR connection - Could not re-establish after default reconnect attempts - ', error);
    });
  };

  public subscribe(event: string, callback: SubscriptionCallback) {
    if (!(event in this.subscriptions)) {
      this.subscriptions[event] = [];
    }
    this.subscriptions[event].push(callback);
  }

  public unsubscribe(event: string) {
    delete this.subscriptions[event];
  }

  private async _dispatchNotification(notification: UserNotificationReadModel) {
    if (notification.messageType === SignalRNotificationType.CHAT_MESSAGE_RECEIVED && notification.relatedEntityId) {
      const channelId = notification.relatedEntityId;
      for await (const subscriptionCallback of this.subscriptions[channelId]) {
        await subscriptionCallback(notification);
      }
    }
  }

  private registerGlobalEvents() {
    const globalEvents = [SignalRMethod.PERMISSION, SignalRMethod.NOTIFICATION];
    globalEvents.forEach((globalEvent) => {
      this.hubConnection.on(globalEvent, async (data) => {
        switch (globalEvent) {
          case SignalRMethod.PERMISSION:
            await this.userStore.loadCurrentUser();
            this.tenantStore.setUserRoles(this.userStore.currentUser()?.tenantRoles);
            break;
          case SignalRMethod.NOTIFICATION:
            await this._dispatchNotification(data);
            break;
          default:
            this.logger.log('Received global event from SignalR - ', data);
            break;
        }
      });
    });
  }

  /* sample usage of signal-r service
  // subscribe to an event
  receiveMessage() {
    this.connection.on(SignalRMethod.NOTIFICATION, (data) => {
      this.logger.log('Received notification from SignalR - ', data);
    });
    // re-subscribe to an event on re-connection
    this.connection.onreconnected(() => {
      this.connection.on(SignalRMethod.NOTIFICATION, (data) => {
        this.logger.log('Received notification from SignalR - ', data);
      });
    });
  }

  // send a req
  async sendMessage(reqData: unknown) {
    try {
      await this.connection.invoke(SignalRMethod.NOTIFICATION, reqData);
    } catch (error) {
      this.logger.error('Error sending data to SignalR - ', error);
    }
  } */
}
